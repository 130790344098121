import React from "react"
import styles from "./partners.module.css"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const PartnerImage = ({ fluid }) => {
    return (
        <div className="col-sm-3 col-6 text-center">
            <Img fluid={fluid} />
        </div>
    )
}

const OurPartners = () => {
    const { imagesJson } = useStaticQuery(graphql`
        {
            imagesJson(name: { eq: "Our Partners" }) {
                id
                images {
                    id
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid_tracedSVG
                        }
                    }
                }
            }
        }
    `)

    return (
        <section className={`${styles.section} section-pad`}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-10 offset-md-1">
                        <h2 className={styles.title}>Our Partners</h2>
                        <p className={styles.desc}>
                            We are integrated with all the leading 3rd party
                            supply partners for video advertising including
                        </p>
                    </div>
                    <div className="col-md-8 offset-md-2">
                        <div className="row">
                            {imagesJson.images.map(x => (
                                <PartnerImage fluid={x.childImageSharp.fluid} key={x.id} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurPartners
