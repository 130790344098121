import React, { useEffect, useRef } from "react"
import styles from "./cross.module.css"
import { useStaticQuery, graphql } from "gatsby"
import CanvasBar from "../common/CanvasBar"

const PlatformItem = ({ placeholder, size = 6, src, children, isWide }) => {
    return (
        <div className={`col-md-${size} p-0`}>
            <div className={styles.item}>
                <img className={styles.placeholder} src={placeholder} alt="" />
                <div
                    className={styles.content}
                    style={{ backgroundImage: `url(${src})` }}
                >
                    {children}
                </div>
            </div>
        </div>
    )
}

const getBase64 = item => item.childImageSharp.fluid.base64
const getSrc = item => item.childImageSharp.fluid.src

const CrossPlatform = () => {
    const data = usePlaceholers()
    const el = useRef(null)

    useEffect(() => {
        if (el && el.current) {
            new window.Isotope(el.current, {
                percentPosition: true,
                masonry: {
                    columnWidth: ".col-md-3",
                },
            })
        }
    }, [])

    return (
        <section className="pt-5 pb-5">
            <header>
                <h1 className="text-center great-text">
                    Cross - Platform Products
                </h1>
                <div className="d-flex justify-content-center pb-5">
                    <CanvasBar />
                </div>
            </header>
            <div className={styles.con}>
                <div ref={el}>
                    <PlatformItem
                        size={6}
                        placeholder={getBase64(data.video)}
                        src={"/images/products/video.gif"}
                    >
                        <div className="d-flex align-items-center h100p pl-3">
                            <div className={styles.videoText}>VIDEO</div>
                        </div>
                    </PlatformItem>
                    <PlatformItem
                        size={6}
                        placeholder={getBase64(data.display)}
                        src={getSrc(data.display)}
                    >
                        <div className="d-flex align-items-center h100p pl-3">
                            <div className={styles.leftText}>DISPLAY</div>
                        </div>
                    </PlatformItem>
                    <PlatformItem
                        size={3}
                        placeholder={getBase64(data.content)}
                        src={getSrc(data.content)}
                    >
                        <div className="flex-center h100p flex-column">
                            <img
                                width="50"
                                height="50"
                                className="mb-2"
                                src="/images/products/content.svg"
                                alt=""
                            />
                            <div>CONTENT</div>
                        </div>
                    </PlatformItem>
                    <PlatformItem
                        size={3}
                        placeholder={getBase64(data.native)}
                        src={getSrc(data.native)}
                    >
                        <div className="d-flex justify-content-center h100p pt-3">
                            <div>NATIVE</div>
                        </div>
                    </PlatformItem>
                    <PlatformItem
                        size={3}
                        placeholder={getBase64(data.email)}
                        src={getSrc(data.email)}
                    >
                        <div className="d-flex align-items-end h100p pl-3 pb-2">
                            <div>EMAIL</div>
                        </div>
                    </PlatformItem>
                    <PlatformItem
                        size={6}
                        placeholder={getBase64(data.social)}
                        src={"/images/products/social.gif"}
                    >
                        <div className="d-flex justify-content-center h100p pt-3">
                            <div className={styles.blackText}>SOCIAL</div>
                        </div>
                    </PlatformItem>
                    <PlatformItem
                        size={6}
                        placeholder={getBase64(data.connectedtv)}
                        src={getSrc(data.connectedtv)}
                    >
                        <div className="d-flex align-items-center h100p pl-3">
                            <div style={{ maxWidth: "40%" }}>CONNECTED TV</div>
                        </div>
                    </PlatformItem>
                    <PlatformItem
                        size={6}
                        placeholder={getBase64(data.mobile1)}
                        src={getSrc(data.mobile1)}
                    >
                        <div className="pt-2 pl-3 h100p">
                            <div>MOBILE</div>
                        </div>
                    </PlatformItem>
                    <PlatformItem
                        size={3}
                        placeholder={getBase64(data.content)}
                        src={getSrc(data.content)}
                    >
                        <div className="flex-center h100p flex-column">
                            <div>AFFILIATE</div>
                        </div>
                    </PlatformItem>
                    <PlatformItem
                        size={3}
                        placeholder={getBase64(data.search)}
                        src={getSrc(data.search)}
                    >
                        <div className="text-center pt-3 h100p">
                            <div className={styles.blackText}>SEARCH</div>
                        </div>
                    </PlatformItem>
                    <PlatformItem
                        size={6}
                        placeholder={getBase64(data.dooh)}
                        src={getSrc(data.dooh)}
                    >
                        <div className="d-flex align-items-center h100p pl-3">
                            <div>
                                DIGITAL -<br /> OUT - OF -<br /> HOME
                            </div>
                        </div>
                    </PlatformItem>
                    <PlatformItem
                        size={3}
                        placeholder={getBase64(data.brand)}
                        src={getSrc(data.brand)}
                    >
                        <div className="d-flex align-items-center justify-content-end h100p p-3">
                            <div className={styles.leftText}>
                                BRAND
                                <br /> SURVEY
                            </div>
                        </div>
                    </PlatformItem>
                </div>
            </div>
        </section>
    )
}

export const placeholder = graphql`
    fragment placeholder on File {
        childImageSharp {
            fluid {
                ...GatsbyImageSharpFluid
            }
        }
    }
`

const usePlaceholers = () =>
    useStaticQuery(graphql`
        {
            video: file(relativePath: { eq: "products/video.jpg" }) {
                ...placeholder
            }
            display: file(relativePath: { eq: "products/display.jpg" }) {
                ...placeholder
            }
            content: file(relativePath: { eq: "products/content.jpg" }) {
                ...placeholder
            }
            social: file(relativePath: { eq: "products/social.jpg" }) {
                ...placeholder
            }
            native: file(relativePath: { eq: "products/native.jpg" }) {
                ...placeholder
            }
            email: file(relativePath: { eq: "products/email.jpg" }) {
                ...placeholder
            }
            connectedtv: file(
                relativePath: { eq: "products/connectedtv.jpg" }
            ) {
                ...placeholder
            }
            dooh: file(relativePath: { eq: "products/dooh.jpg" }) {
                ...placeholder
            }
            brand: file(relativePath: { eq: "products/brand.jpg" }) {
                ...placeholder
            }
            mobile1: file(relativePath: { eq: "products/mobile1.jpg" }) {
                ...placeholder
            }
            search: file(relativePath: { eq: "products/search.jpg" }) {
                ...placeholder
            }
        }
    `)

export default CrossPlatform
