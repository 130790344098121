import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import CanvasBar from "../common/CanvasBar"

const Logos = () => {
    const { imagesJson } = useStaticQuery(graphql`
        {
            imagesJson(name: { eq: "clients" }) {
                id
                images {
                    id
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid_tracedSVG
                        }
                    }
                }
            }
        }
    `)

    return (
        <section className="section-pad">
            <header>
                <h1 className="text-center great-text">They Trust!</h1>
                <div className="d-flex justify-content-center">
                    <CanvasBar />
                </div>
            </header>
            <div className="container pt-5">
                <div className="row justify-content-center">
                    {imagesJson.images.map(x => (
                        <div className="col-md-2 col-6 mb-5" key={x.id}>
                            <div className="pl-2 pr-2">
                                <Img
                                    className="clients-list-img"
                                    imgStyle={{
                                        objectFit: "contain",
                                    }}
                                    fluid={x.childImageSharp.fluid}
                                    alt=""
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Logos
