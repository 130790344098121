import React from "react"

const BannerSection = () => {
    return (
        <section className="videobanner" id="hero-section">
            <video
                poster="/videos/stars.jpg"
                className="videobanner-img"
                loop
                autoPlay
                muted
            >
                <source src="/videos/stars.mp4" type="video/mp4" />
            </video>
            <div className="videobanner-content d-flex justify-content-center align-items-center">
                <div id="hero-title">
                    <h1 className="videobanner-title">Creative + Intellect</h1>
                    <p className="videobanner-sub-title">
                    Anytime.&ensp; Everytime.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default BannerSection
