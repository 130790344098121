import React from "react"

const Item = ({ icon, title }) => {
    return (
        <div className="col-md col-6 text-center text-sm text-white">
            <img
                className="mb-3"
                src={`/images/services/${icon}.svg`}
                style={{ maxWidth: "75px" }}
            />
            <div>{title}</div>
        </div>
    )
}

const Things = () => {
    return (
        <section className="pt-5 pb-5" style={{ background: "#000" }}>
            <div className="container-fluid">
                <div className="h2 font-weight-bold text-center text-white">
                    Things We Help You Out With
                </div>
                <div className="row pt-5">
                    <Item icon="programatic" title="Programatic" />
                    <Item icon="bidding" title="Real Time Bidding" />
                    <Item icon="creative" title="Custom Creative" />
                    <Item icon="fullstack" title="Full Stack Development" />
                    <Item icon="insight" title="Audience Insight" />
                    <Item icon="analytics" title="Reporting & Analytics" />
                </div>
            </div>
        </section>
    )
}

export default Things
