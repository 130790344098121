import React from "react"
import Layout from "../components/common/Layout"
import BannerSection from "../components/home/BannerSection"
import CrossPlatform from "../components/home/CrossPlatform"
import GreatSection from "../components/home/GreatSection"
import Logos from "../components/home/Logos"
import MediaKit from "../components/home/MediaKit"
import OurPartners from "../components/home/OurPartners"
import Things from "../components/home/Things"

const IndexPage = () => (
    <Layout isheaderTransparent>
        <BannerSection />
        <GreatSection />
        <OurPartners />
        <CrossPlatform />
        <Things />
        <Logos />
        <MediaKit />
    </Layout>
)

export default IndexPage
