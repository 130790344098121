import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import CanvasBar from "../common/CanvasBar"

const GreatSection = () => {
    const data = useImages()

    const getImage = i => data[`image${i}`].childImageSharp.fluid

    return (
        <section className="section-pad">
            <header>
                <h1 className="text-center great-text">We are Experts!</h1>
                <div className="d-flex justify-content-center">
                    <CanvasBar />
                </div>
            </header>
            <div className="container-fluid pt-5">
                <div className="row">
                    <GrtImage image={getImage(0)} />
                    <GrtImage image={getImage(1)} size={6} />
                    <GrtImage image={getImage(2)} />
                </div>
                <div className="row">
                    <GrtImage image={getImage(3)} />
                    <GrtImage image={getImage(4)} />
                    <GrtImage image={getImage(5)} />
                    <GrtImage image={getImage(6)} />
                </div>
                <div className="row">
                    <GrtImage image={getImage(7)} size={6} />
                    <GrtImage image={getImage(8)} />
                    <GrtImage image={getImage(9)} />
                </div>
            </div>
        </section>
    )
}

const GrtImage = ({ image, size = 3 }) => {
    return (
        <div className={`col-md-${size}`}>
            <Img className="w-100 mb-4" fluid={image} alt="" />
        </div>
    )
}

export const grtImage = graphql`
    fragment grtImage on File {
        childImageSharp {
            fluid {
                ...GatsbyImageSharpFluid
            }
        }
    }
`

const useImages = () =>
    useStaticQuery(graphql`
        {
            image0: file(relativePath: { eq: "platforms/1.jpg" }) {
                ...grtImage
            }
            image1: file(relativePath: { eq: "platforms/2.jpg" }) {
                ...grtImage
            }
            image2: file(relativePath: { eq: "platforms/3.jpg" }) {
                ...grtImage
            }
            image3: file(relativePath: { eq: "platforms/4.jpg" }) {
                ...grtImage
            }
            image4: file(relativePath: { eq: "platforms/5.jpg" }) {
                ...grtImage
            }
            image5: file(relativePath: { eq: "platforms/6.jpg" }) {
                ...grtImage
            }
            image6: file(relativePath: { eq: "platforms/7.jpg" }) {
                ...grtImage
            }
            image7: file(relativePath: { eq: "platforms/8.jpg" }) {
                ...grtImage
            }
            image8: file(relativePath: { eq: "platforms/9.jpg" }) {
                ...grtImage
            }
            image9: file(relativePath: { eq: "platforms/10.jpg" }) {
                ...grtImage
            }
        }
    `)

export default GreatSection
