import React from "react"
import { Link } from "gatsby"
import styles from "./mediakit.module.css"

const MediaKit = () => {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className={`col-md-6 ${styles.orangebgsection}`}>
                    <p className={styles.brochuretitle}>Media-Kit</p>
                    <p className={styles.brochurecontent}>
                        Download free media-kit and get to know us better click
                        here
                    </p>
                    <a
                        href="/brochure.pdf"
                        download="billiontags-media-kit"
                        className={styles.borderedbtn}
                    >
                        Download Now
                    </a>
                </div>
                <div className={`col-md-6 ${styles.contactbgsection}`}>
                    <p className={styles.contacttitle}>
                        Are Your Fingertips Tingly?
                    </p>
                    <p className={styles.contactcontent}>
                        Wait No More, It's the Urge to Contact Us.
                    </p>
                    <Link to="/contact/" className={styles.bgbtn}>
                        Contact Us Now →
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default MediaKit
